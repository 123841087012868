import React, { ComponentClass, FunctionComponent, useMemo } from 'react'
import Loadable, { LoadableComponent } from 'react-loadable'
import LoadingIndicator from '../LoadingIndicator'

type IconsSize = 'SM' | 'MD' | 'LG' | 'XL'

type IOrangeDSIconProps = {
  icon: string;
  color?: string;
  size: IconsSize;
  className?: string;
}

interface IOrangeDSIcon {
  color?: string;
  size: IconsSize;
  className?: string;
}

export function IntercoIcons ({ icon, color, size, className }: IOrangeDSIconProps) {
  const SvgIcon: (ComponentClass<IOrangeDSIcon> & LoadableComponent) | (FunctionComponent<IOrangeDSIcon> & LoadableComponent) = useMemo(() => (
    Loadable({
      loader: () => import(`@interco/icons/build-v4/orangeds/${size}/${icon}`),
      loading: () => <LoadingIndicator size='small' />,
    })
  ), [])
  return (
    <SvgIcon color={color} size={size} className={className} />
  )
}

IntercoIcons.defaultProps = {
  icon: '',
  color: 'orange-base',
  className: '',
}

export default IntercoIcons
