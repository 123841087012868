import React, { useState, useLayoutEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { Modal } from 'src/components/Modal'

import QrCodeNaoCorrentista from '../assets/images/qr-code-global-account-nao-correntista.png'

import pageContextJSON from './pageContext.json'

import {
  Hero,
} from './sections/_index'

const WIDTH_MD = 768

const GlobalAccount = () => {
  const width = useWidth(200)
  const domReady = useDomReady()

  const [ isModalOpenAccount, setIsModalOpenAccount ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Conta Internacional Inter',
    element_action: 'click button',
    element_name: 'Não sou cliente Inter',
  }

  const closeModal = () => {
    setIsModalOpenAccount(false)
  }

  const openAccountModal = domReady && (
    <Modal isModalOpen={isModalOpenAccount} setIsModalOpen={setIsModalOpenAccount} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={closeModal}
        dataLayer={dataLayer}
        formGlobalParceiros
        qrBaixeApp={QrCodeNaoCorrentista}
        customOrigin='LP Global Account'
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(WIDTH_MD > width)
  }, [ width, isMobile ])

  return (
    <Layout pageContext={pageContextJSON}>
      {openAccountModal}
      <Hero setIsModalOpenAccount={setIsModalOpenAccount} />
    </Layout>
  )
}

export default GlobalAccount
