import { brand } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${brand.sand};
`

export const ContainerLista = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ul {
    list-style: none;
  }

  li svg {
    width: 22px;
    height: 22px;
  }
  li span {
    margin-left: 8px;
  }
  li:not(:last-of-type) {
    margin-bottom: 14px;
  }
`
