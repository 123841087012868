import React from 'react'
import IntercoIcons from 'src/components/IntercoIcons'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import * as S from './style'
import { brand } from 'src/styles/colors'

type heroType = {
  setIsModalOpenAccount: Function;
}

type ListaCarteiraT = {
  id: string;
  iconName: string;
  iconColor: string;
  text: string;
}

const CONTEUDO_LISTA = [
  {
    id: 'dobra_01_exchange',
    iconName: 'exchange',
    iconColor: brand.claryOrange,
    text: 'Troque reais por dólares em poucos cliques',
   },
  {
    id: 'dobra_01_gift',
    iconName: 'gift',
    iconColor: brand.claryOrange,
    text: 'Adquira gift cards com cashback em dólar',
  },
  {
    id: 'dobra_01_chip',
    iconName: 'sim-card',
    iconColor: brand.claryOrange,
    text: 'Viaje com chip internacional sem se preocupar com o sinal de internet',
  },
]

const Hero = ({ setIsModalOpenAccount }: heroType) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 order-md-last'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/background-parceiros-sm/image.webp'
              altDescription='global account inter'
              className='d-md-none'
            />
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/background-parceiros-md/image.webp'
              altDescription='global account inter'
              className='d-none d-md-block d-lg-none'
            />
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/background-parceiros-lg/image.webp'
              altDescription='global account inter'
              className='d-none d-lg-block d-xl-none'
            />
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/background-parceiros-xl/image.webp'
              altDescription='global account inter'
              className='d-none d-xl-block'
            />
          </div>
          <div className='col-12 col-md-6'>
            <h1 className='font-citrina fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-brandy-primary--500 fw-600 mb-4'>
              Global Account Inter: <span className='d-xl-block'>com você no mundo todo</span>
            </h1>
            <div>
              <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Ative sua conta em dólar gratuita agora!</p>
              <S.ContainerLista>
                <ul>
                  {CONTEUDO_LISTA.map((listItem: ListaCarteiraT) => (
                    <li key={listItem.id} className='d-flex align-items-center'>
                      <div>
                        <IntercoIcons size='MD' color={listItem.iconColor} icon={listItem.iconName} />
                      </div>
                      <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500 d-block ml-3 mb-0'>{listItem.text}</p>
                    </li>
                  ))}
                </ul>
              </S.ContainerLista>
            </div>
            <div className='mt-3'>
              <button
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100 fw-600'
                title='Cadastrar'
                onClick={() => {
                  setIsModalOpenAccount(true)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Global Account Inter: com você no mundo todo',
                    element_action: 'click button',
                    element_name: 'Cadastrar',
                  })
                }}
              >
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
