import React, { forwardRef, ForwardedRef } from 'react'

import { LoadingIndicator } from './style'

type LoadingIndicatorProps = {
  className?: string;
  size?: string;
}

const LoadingIndicatorComponent = forwardRef(({ className, size }: LoadingIndicatorProps, ref: ForwardedRef<SVGSVGElement>) => (
  <div className={`mx-auto ${className}`}>
    <div className='d-flex justify-content-center align-items-center'>
      <LoadingIndicator ref={ref} size={size} width='36' height='36' viewBox='0 0 36 36' className='mb-2'>
        <path d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831' fill='none' />
      </LoadingIndicator>
    </div>
    <span className='text-white fs-12 lh-15'>Aguarde...</span>
  </div>
))

LoadingIndicatorComponent.defaultProps = {
  size: 'default',
}

export default LoadingIndicatorComponent
